import {EventBus} from "../../common/ts/events/EventBus";
import {DropdownElementClickEvent} from "../../common/ts/events/Events";
import CurrencySelectionService from "../../common/ts/services/CurrencySelectionService";
import HipflatPage from "./common/HipflatPage";

const currencySelectionService = new CurrencySelectionService();
const page = new HipflatPage()

EventBus.getInstance().subscribe(DropdownElementClickEvent.TYPE, async (event) => {
    const eventElement = event as DropdownElementClickEvent
    if (eventElement.payload.dropdownId == 'currencyClickDropdown' || eventElement.payload.dropdownId == 'currencyHoverDropdown') {
        const dataValues = eventElement.payload.dataValues;
        const currency = dataValues.value;
        await currencySelectionService.changeCurrency(currency);
        location.reload();
    } else if (
        (eventElement.payload.dropdownId == 'buyClickDropdown' ||
         eventElement.payload.dropdownId == 'rentClickDropdown' ||
         eventElement.payload.dropdownId == 'projectsClickDropdown'
        ) &&
        eventElement.payload.element.getElementsByTagName('a').length == 1
    ) {
        eventElement.payload.event.stopPropagation();
        eventElement.payload.event.preventDefault();
        document.location.href = eventElement.payload.element.getElementsByTagName('a')[0].href;
    }
});

document.querySelectorAll("#js-burger-menu").forEach((elements) => {
    elements.addEventListener('click', (event) => {
        event.stopPropagation();
        event.preventDefault();
        page.block();
        document.getElementById("side-menu")?.classList.add("visible");
    });
});

document.getElementById("close-side-menu")?.addEventListener('click', () => {
    page.unblock();
    document.getElementById("side-menu")?.classList.remove("visible");
});

