export default class CurrencySelectionService {
    public async changeCurrency(currencyISO4217: string): Promise<void> {
        await fetch('/api/currency', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                currency: currencyISO4217,
            }),
        });
    }
}
