
export default class HipflatPage {
    block() {
        document.body.classList.add("blocked");
    }

    unblock() {
        document.body.classList.remove("blocked");
    }

    blockWithBar() {
        document.body.classList.add("blocked-with-bar");
    }

    unblockWithBar() {
        document.body.classList.add("blocked-with-bar");
    }
}